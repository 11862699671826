export function applyNavigationEffect() {
    const title = "Burgverein Hohenfels e.V.";
    const item = document.querySelectorAll(".navbar-brand");
    if (item.length > 0 && item[0]) {
      item[0].classList.remove("active");
    }
  
    const isRuinHistorySite = document.getElementById("ruin-history") !== null;
    const isRuinGallerySite = document.getElementById("ruin-gallery") !== null;
    const isRuinPositionSite = document.getElementById("ruin-position") !== null;
    const isRuinVisualizationSite =
      document.getElementById("ruin-visualization") !== null;
  
    const isGuildHistorySite = document.getElementById("guild-history") !== null;
    const isChairmanSite =
      document.getElementById("association-chairman") !== null;
  
    const isStatuteSite = document.getElementById("statute") !== null;
  
    const isTasksSite = document.getElementById("tasks") !== null;
    const isHomePage = document.getElementById("home") !== null;

    const navbar = document.getElementById("navbar-nav");
    if (navbar && navbar.classList.contains("show")) {
      navbar.classList.remove("show");
    } else if (isRuinHistorySite) {
      document.title = title + " | Geschichte der Burgruine";
    } else if (isRuinGallerySite) {
      document.title = title + " | Bilder der Ruine";
    } else if (isRuinPositionSite) {
      document.title = title + " | Lage der Ruine";
    } else if (isRuinVisualizationSite) {
      document.title = title + " | Rekonstruktions-Modelle";
    } else if (isTasksSite) {
      document.title = title + " | Aufgaben des Burgvereins Hohenfels e.V.";
    } else if (isGuildHistorySite) {
      document.title = title + " | Geschichte des Vereins";
    } else if (isChairmanSite) {
      document.title = title + " | Der Vorstand";
    } else if (isStatuteSite) {
      document.title = title + " | Satzung";
    } 
    else if(isHomePage) {
        document.title = title + " | Startseite";
    }
    else {
      document.title = title;
    }
  
    const root = document.getElementById("root");
    if (root) {
      root.classList.remove("home-page");
    }
  }
  