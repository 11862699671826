import React from "react";
import { Button, ProgressBar, InputGroup, FormControl } from "react-bootstrap";
import {
  BsPlayCircleFill,
  BsPauseCircleFill,
  BsVolumeMuteFill,
  BsVolumeDownFill,
  BsVolumeUpFill,
  BsArrowClockwise,
  BsArrowCounterclockwise,
} from "react-icons/bs";

/**
 * AudioPlayer component to play audio files with controls.
 *
 * @class AudioPlayer
 * @extends React.Component
 *
 * @param {Object} props - The component props.
 * @param {string} props.audioSrc - The source URL of the audio file.
 * @param {string} [props.audioName] - The display name of the audio file (defaults to audioSrc if not provided).
 *
 * @state {boolean} isPlaying - Indicates if the audio is currently playing.
 * @state {number} currentTime - Current playback time of the audio.
 * @state {number} volume - Current volume level (range from 0 to 1).
 * @state {number} progress - Progress of audio playback as a percentage.
 *
 * @example
 * <AudioPlayer audioSrc="path/to/audio.mp3" audioName="My Audio" />
 */
class AudioPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.audioRef = React.createRef();
    this.audioSrc = props.audioSrc;
    this.audioName = props.audioName || props.audioSrc;

    this.state = {
      isPlaying: false,
      currentTime: 0,
      volume: 1, // Range between 0 and 1
      progress: 0,
    };
  }

  /**
   * Toggles play/pause state of the audio.
   */
  togglePlayPause() {
    const { isPlaying } = this.state;
    const audio = this.audioRef.current;
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    this.setState({ isPlaying: !isPlaying });
  }

  /**
   * Updates the current playback time and progress percentage.
   */
  handleTimeUpdate() {
    const audio = this.audioRef.current;
    if (audio && !isNaN(audio.duration)) {
      const progressPercentage = (audio.currentTime / audio.duration) * 100;
      this.setState({ progress: progressPercentage });
      if (progressPercentage >= 100) {
        this.setState({ isPlaying: false, progress: 0 });
      }
    }
  }

  /**
   * Returns the appropriate volume icon based on the current volume level.
   * @returns {JSX.Element} Volume icon component.
   */
  getVolumeIcon() {
    const { volume } = this.state;
    if (volume <= 0) {
      return <BsVolumeMuteFill />;
    } else if (volume > 0 && volume <= 0.5) {
      return <BsVolumeDownFill />;
    } else {
      return <BsVolumeUpFill />;
    }
  }

  /**
   * Handles volume change from the range input.
   * @param {Event} e - The change event from the input.
   */
  handleVolumeChange(e) {
    const newVolume = e.target.value;
    this.setState({ volume: newVolume });
    if (this.audioRef.current) {
      this.audioRef.current.volume = newVolume;
    }
  }

  /**
   * Skips forward or backward in the audio by the specified number of seconds.
   * @param {number} seconds - The number of seconds to skip (can be negative).
   */
  skipTime(seconds) {
    const audio = this.audioRef.current;
    if (audio && !isNaN(audio.currentTime)) {
      audio.currentTime += seconds;
    }
  }

  /**
   * Renders the AudioPlayer component.
   * @returns {JSX.Element} The rendered component.
   */
  render() {
    const { isPlaying, progress, volume } = this.state;
    return (
      <div className="audio-player">
        <p>{this.audioName}</p>
        <audio
          id="Player"
          ref={this.audioRef}
          src={this.audioSrc}
          onTimeUpdate={this.handleTimeUpdate.bind(this)}
          onEnded={() => this.setState({ isPlaying: false })}
          width={1}
        />
        <Button
          variant={isPlaying ? "danger" : "success"}
          onClick={this.togglePlayPause.bind(this)}
          className="playPauseBtn"
        >
          {isPlaying ? (
            <BsPauseCircleFill size={70} />
          ) : (
            <BsPlayCircleFill size={60} />
          )}
        </Button>
        <div className="audio-controls">
          <Button variant="primary" onClick={() => this.skipTime(-5)}>
            <BsArrowCounterclockwise size={50} />
            -5sec
          </Button>

          <Button variant="primary" onClick={() => this.skipTime(5)}>
            <BsArrowClockwise size={50} />
            +5sec
          </Button>
        </div>
        <ProgressBar
          striped
          variant="success"
          now={progress}
          label={`${Math.round(progress)}%`}
        />
        <InputGroup className="volume-input">
          <InputGroup.Text>{this.getVolumeIcon()}</InputGroup.Text>
          <FormControl
            type="range"
            min="0"
            max="1"
            step="0.1"
            value={volume}
            onChange={this.handleVolumeChange.bind(this)}
          />
        </InputGroup>
      </div>
    );
  }
}

export default AudioPlayer;
