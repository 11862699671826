// App.js
import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
import axios from "axios";

import { BsInstagram, BsFillEnvelopeFill } from "react-icons/bs";
import NavigationBar from "./components/Navbar";

import Home from "./pages/Home";

import DataProtection from "./pages/DataProtection";
import Imprint from "./pages/Imprint";
import BecomeMember from "./pages/BecomeMember";

//ruin Ruine
import History from "./pages/ruin/History";
import Position from "./pages/ruin/Position";
import RuinImages from "./pages/ruin/Images";
import Visualization from "./pages/ruin/Visualization";

//guild Der Verein "Wer wir sind"
import Tasks from "./pages/guild/Tasks";
import GuildHistory from "./pages/guild/History";
import AssociationChairman from "./pages/guild/AssociationChairman";
import Statute from "./pages/guild/Statute";
import AssociationLogo from "./pages/guild/AssociationLogo";
//import Donation from './pages/guild/Donation';

//projects
import CellarProject from "./pages/projects/Cellar";
import ModelProject from "./pages/projects/Models";
import DonationBoard from "./pages/projects/DonationBoard";
import LectureTrail from "./pages/projects/LectureTrail";
//import MidSummerNight from './pages/special/SummerNight';
import GuestBook from "./pages/GuestBook";

//LectureTrail
import EntranceBuchenau from "./pages/lecturetrail/EntranceBuchenau";
import EntranceCemetery from "./pages/lecturetrail/EntranceCemetery";
import EntranceSportField from "./pages/lecturetrail/EntranceSportField";

const TRACKING_ID = "G-3N1MT9W9EB"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const tagManagerArgs = {
  gtmId: TRACKING_ID,
};

TagManager.initialize(tagManagerArgs);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalVisitors: 0,
    };
    this.handleAcceptCookies = this.handleAcceptCookies.bind(this);
    this.updateVisitorCount = this.updateVisitorCount.bind(this);
    this.handleAcceptCookies = this.handleAcceptCookies.bind(this);
  }
  /**
   * Lifecycle method called after the component is mounted.
   * Fetches the manufacturer list from the API and initializes focus on the search input field.
   */
  async componentDidMount() {
    if (getCookieConsentValue() === "true") {
      this.updateVisitorCount();
    }

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://burgverein-hohenfels.de/visitors/total",
      headers: {},
    };

    try {
      const response = await axios.request(config);
      console.log(response);

      this.setState({
        totalVisitors: response.data.totalVisitors,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async updateVisitorCount() {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://burgverein-hohenfels.de/visitors/update",
      headers: {},
    };

    try {
      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));
    } catch (error) {
      console.log(error);
    }
  }

  handleAcceptCookies() {
    this.updateVisitorCount();
    window.location.reload(true);
  }

  render() {
    return (
      <>
        <header>
          <NavigationBar />
        </header>
        <main>
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/ruinen/geschichte" element={<History />} />
              <Route path="/ruinen/lage" element={<Position />} />
              <Route path="/ruinen/bilder" element={<RuinImages />} />
              <Route
                path="/ruinen/rekonstruktions-modelle"
                element={<Visualization />}
              />
              <Route path="/verein/aufgaben" element={<Tasks />} />
              <Route path="/verein/historie" element={<GuildHistory />} />
              <Route
                path="/verein/vorstand"
                element={<AssociationChairman />}
              />
              <Route path="/verein/satzung" element={<Statute />} />
              <Route path="/verein/logo" element={<AssociationLogo />} />

              <Route path="/projekte/keller" element={<CellarProject />} />
              <Route path="/projekte/modell" element={<ModelProject />} />
              <Route path="/projekte/lehrpfad" element={<LectureTrail />} />
              <Route
                path="/projekte/spendentafel"
                element={<DonationBoard />}
              />

              <Route path="/mitglied-werden" element={<BecomeMember />} />
              <Route path="/gaestebuch" element={<GuestBook />} />
              <Route path="/datenschutz" element={<DataProtection />} />
              <Route path="/impressum" element={<Imprint />} />
              {/**
               * LEHRPFAD
               */}
              <Route
                path="lehrpfad/eingang/buchenau"
                element={<EntranceBuchenau />}
              />
              <Route
                path="lehrpfad/eingang/friedhof"
                element={<EntranceCemetery />}
              />
              <Route
                path="lehrpfad/eingang/sportplatz"
                element={<EntranceSportField />}
              />
              <Route path="*" element={<Home />} />
            </Routes>
          </Router>
        </main>
        <CookieConsent
          location="bottom"
          buttonText="Ich stimme zu"
          cookieName="cookies"
          buttonClasses="btn btn-dark"
          expires={150}
          onAccept={this.handleAcceptCookies}
        >
          Diese Website verwendet Cookies, um sicherzustellen, dass du die beste
          Erfahrung auf unserer Website erhältst.
        </CookieConsent>
        <footer>
          <div id="visitors">
            Gesamtbesucherzahl: {this.state.totalVisitors}
          </div>
          <div id="insta-link" className="footer-link">
            <BsInstagram />
            <a
              href="https://www.instagram.com/burgverein_hohenfels_lahn/"
              target="_blank"
            >
              Folgt uns auf Instagram
            </a>
          </div>
          <div id="mail-link" className="footer-link">
            <BsFillEnvelopeFill />
            <a href="mailto:burg.hohenfels@googlemail.com" id="footer-mail">
              Schreibt uns eine E-Mail
            </a>
          </div>

          <div id="copyright">Copyright 2024, Burgverein Hohenfels e.V.</div>
        </footer>
      </>
    );
  }
}

export default App;
