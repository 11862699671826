import React from "react";

/**
 * VideoPlayer component displays a video player with controls.
 *
 * @class VideoPlayer
 * @extends React.Component
 *
 * @param {Object} props - The component props.
 * @param {string} props.videoSrc - The source URL of the video file.
 * @param {string} [props.videoName] - The display name of the video (defaults to videoSrc if not provided).
 * @param {string} [props.videoType] - The MIME type of the video (defaults to "video/mp4").
 *
 * @example
 * <VideoPlayer videoSrc="path/to/video.mp4" videoName="My Video" videoType="video/mp4" />
 */
class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    this.videoSrc = props.videoSrc;
    this.videoName = props.videoName || props.videoSrc;
    this.videoType = props.videoType || "video/mp4";
    this.state = {
      videoStarted: false,
    };
  }

  /**
   * Renders the VideoPlayer component.
   * @returns {JSX.Element} The rendered component.
   */
  render() {
    return (
      <div className="video-player">
        <p>{this.videoName}</p>
        <video controls autoPlay>
          <source src={this.videoSrc} type={this.videoType} />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  }
}

export default VideoPlayer;
