import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { applyNavigationEffect } from "../../effects/NavigationEffect";
import CardWithImageComponent from "../../components/CardWithImageComponent.js";

class Position extends Component {
  componentDidMount() {
    applyNavigationEffect(); // Call the navigation effect
  }

  render() {
    return (
      <div id="ruin-position">
        <Container className="main-container">
          <CardWithImageComponent
            id="lage-01"
            className="card-with-image"
            image="../assets/images/general_images/lage-1.png"
            header="Lage der Burg"
            content={
              <div>
                Geographische Lage des ehemaligen Schloss Hohenfels. <br />
                Farblich hervorgehoben die Ausmaße der ehemaligen Cent Dautphe
                in denen die „von Hohenfels“ die Gerichtsgewalt innehatten.
              </div>
            }
          />
          <CardWithImageComponent
            id="karte-schloss-hohenfels"
            className="card-with-image"
            image="../assets/images/general_images/karte-schloss-hohenfels.jpg"
            header="Karte Schloss Hohenfels"
          />
        </Container>
      </div>
    );
  }
}

export default Position;
