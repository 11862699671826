import React, { Component } from "react";
import { Container, Card } from "react-bootstrap";

import { applyNavigationEffect } from "../effects/NavigationEffect.js";

class Imprint extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    applyNavigationEffect();
  }

  render() {
    return (
      <div id="imprint" className="webpage">
        <Container className="main-container">
          <Card>
            <Card.Header>Impressum</Card.Header>
            <Card.Body>
              <Card.Text>
                Diese Webseite wird vom Burgverein Hohenfels e.V. betrieben und
                gewartet.
                <br />
                <br />
                <b>Geschäftsadresse:</b>
                <br />
                Im Steinfeld 19
                <br />
                35232 Dautphetal OT Allendorf a. Hohenfels
                <br />
                <br />
                Verantwortliche für Texte und Inhalte: <br />
                <ul>
                  <li>Timon Flemming</li>
                  <li>Stefan Rehn</li>
                </ul>
                Mail-Adresse: <br />
                <a
                  href="mailto:burg.hohenfels@googlemail.com"
                  style={{ color: "#212529" }}
                >
                  burg.hohenfels@googlemail.com
                </a>
              </Card.Text>
            </Card.Body>
          </Card>
        </Container>
      </div>
    );
  }
}

export default Imprint;
