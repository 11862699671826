import React, { Component } from "react";
import axios from "axios";
import { Container, Card } from "react-bootstrap";
import useNavigationEffect from "../../effects/NavigationEffect.js";
import CardWithImageComponent from "../../components/CardWithImageComponent.js";

class RuinImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
    };
  }

  componentDidMount() {
    this.fetchImages();
  }

  fetchImages = async () => {
    try {
      const response = await axios.post(
        "https://burgverein-hohenfels.de/images/ruin"
      );
      this.setState({ images: response.data });
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  render() {
    const { images } = this.state;

    return (
      <div id="ruin-gallery" className="webpage">
        <Container className="main-container">
          <CardWithImageComponent
            id="current-picture"
            className="card-with-image"
            image="../assets/images/general_images/ruine-einsturz.jpg"
            header="Bilder der Burgruine"
            content="Einblick in den derzeitigen Zustand des Kellergewölbes: ein Mittelteil der Gewölbedecke ist eingestürzt."
          />
          <div id="images">
            {images.map((imageUrl, index) => (
              <CardWithImageComponent
                key={"ruin_images-" + index}
                id={"ruin_images-" + index}
                image={imageUrl}
                className="card-with-image"
              />
            ))}
          </div>
        </Container>
      </div>
    );
  }
}

export default RuinImages;
