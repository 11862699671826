import React, { Component } from "react";
import { Container, Card } from "react-bootstrap";
import { applyNavigationEffect } from "../../effects/NavigationEffect";
import CardWithImageComponent from "../../components/CardWithImageComponent.js";

class RuinHistory extends Component {
  componentDidMount() {
    applyNavigationEffect(); // Call the navigation effect
  }

  render() {
    return (
      <div id="ruin-history" className="webpage">
        <Container className="main-container">
          <Card>
            <Card.Header>Geschichte der Hohenfelser Ruinen</Card.Header>
            <Card.Body>
              <b>Vorgeschichte liegt im Dunkeln</b> <br />
              Die Erstbesiedelung des Hohenfels erfolgte schon weit vor dem Jahr
              1174 (dem Jahr der Ersterwähnung). Wann genau ist nicht bekannt.
              Als Orientierung kann die Blütezeit der Burgenerrichtung in
              Mitteleuropa herangezogen werden, die liegt in etwa zwischen den
              Jahren 900-1100. Es darf angenommen werden, dass auch der
              Hohenfels in dieser Zeitspanne, der sogenannten
              „Herrschaftsfestigung des Reiches“ erbaut wurde. Ebenso wie der
              genaue Zeitpunkt der Erbauung der Mittelalterlichen Hohenfels
              Anlagen, liegt auch der Ursprung ihrer Bewohner im Dunkel der
              Geschichte. Doch auch wenn Belege dafür fehlen, so führen doch
              einige Indizien zu den Gisonen, die mindestens vom Jahr 1008 an
              bis etwa 1137 nur ein paar Kilometer vom Hohenfels entfernt auf
              der Hollende saßen. Vermutlich ist das Aussterben der Gisonen auch
              ein Faktor gewesen, der zu einem Macht- und Bedeutungszuwachs der
              Hohenfelsbewohner geführt hatte.
              <br />
              <br />
              <b>Ersterwähnung 1174</b> <br />
              Im Jahr 1174 ist Lodewicus (Ludwig) de Honsvels (von Hohenfels)
              als Zeuge in einer Belehnungsurkunde erwähnt:
              <br />
              Kaiser Friedrich I. (Barbarossa) bestätigt in dieser Urkunde die
              Belehnung von Graf Engelbert von Berg mit der Burg Windeck (liegt
              an der Sieg, auf halber Strecke zwischen Bonn und Siegen) durch
              Graf Heinrich Raspo (von Thüringen).
              <br />
              Der westlichste Teil der Hohenfels Anlagen war derjenige, welcher
              zur Zeit der Ersterwähnung bereits existierte.
              <br />
              <br />
              <br />
              <b>Machteinbuße um 1238</b>
              <br />
              Die Hohenfelser waren Gerichtsherren der Cent Dautphe{" "}
              <a href="/ruinen/lage">(Siehe Karte)</a> innerhalb der Grafschaft
              Stiffe, hatten aber offensichtlich selbst eine grafengleiche
              Stellung.
              <br />
              Sie kommen spätestens 1238 zwischen die Fronten des Erzbistums
              Mainz und den Thüringer Landgrafen.
              <br />
              Die Auseinandersetzung gipfelte zunächst in der Besetzung der Cent
              Dautphe durch die Thüringer Grafen 1238.
              <br />
              <br />
              <b>Vertrag 1249</b>
              <br />
              Eine Urkunde des Jahres 1248 lässt erkennen, dass die nominellen
              Gerichtsherren der bis an die Sackpfeife reichenden Cent Dautphe,
              nämlich die Herren von Hohenfels, gezwungen werden, auf alle ihre
              Rechte in der Cent Dautphe zu verzichten.
              <br />
              Eine weitreichende Familiendynastie sowie Besitzungen bis nach
              Fritzlar sorgten dennoch dafür, dass die Familie von Hohenfels
              auch danach noch Rang und Namen in der neuen Landgrafschaft namens
              Hessen hatte.
              <br />
              <br />
              <b>Vermeintliche Zerstörung 1293</b>
              <br />
              Ob die Zerstörung der Hohenfels Anlagen im Jahr 1293 durch den
              ersten Hessischen Landgrafen Heinrich I. tatsächlich stattgefunden
              hat, wie es der Chronist der hessischen Landgrafen „Wiegand
              Gerstenberg“ berichtet, ist nicht sicher. <br />
              Archäologische Funde lassen den Schluss zu, dass Gerstenberg
              mitunter zum Ruhm und im Sinne seiner Geldgeber schrieb und einige
              Burgen der Region durch alteingesessene Adlige noch länger bewohnt
              waren.
              <br />
              <br />
              <b>Adel existiert noch bis ins 16.Jh.</b>
              <br />
              Die Familie von Hohenfels hatte über lange Zeit noch Einfluss in
              Mittel- und Oberhessen. <br />
              Der letzte Hohenfelser starb jedoch im 16.Jahrhundert.
            </Card.Body>
          </Card>
          <CardWithImageComponent
            id="wappen-adelsgeschlecht"
            className="card-with-image"
            header="Das Wappen des Adelsgeschlecht"
            image="../assets/images/general_images/Wappen_derer_von_Hohenfels_(Hessen).png"
            content={
              <div>
                Es gibt Spekulationen darüber, dass die Herren von Hohenfels die
                Cent Dautphe als Reichslehen übertragen bekamen. <br />
                Auch hier taucht neben der geographischen Nähe wieder eine
                Analogie zu den Gisonen auf, welche den Schutz des nahegelegenen
                Reichs Stifts Wetter sicher stellen sollten. <br />
                Eine enge Bindung zum Reich könnte auch das Wappen der Familie
                von Hohenfels erklären, das über Jahrhunderte immer ein
                Adlerflügel in unterschiedlicher Gestaltung war, denn der Adler
                war das Wappentier des Reiches.
              </div>
            }
          />
        </Container>
      </div>
    );
  }
}

export default RuinHistory;
