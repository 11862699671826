import React, { Component } from "react";
import { Container, Card } from "react-bootstrap";
import { applyNavigationEffect } from "../../effects/NavigationEffect";

class LectureTrail extends Component {
  componentDidMount() {
    applyNavigationEffect(); // Call the navigation effect
  }

  render() {
    return (
      <div id="project-lecturetrail" className="webpage">
        <Container className="main-container">
          <Card className="card-web">
            <Card.Header>Lehrpfad</Card.Header>
            {/**<Card.Img variant="center" src="../../images/general_images/lehrpfad.png" />*/}
            <Card.Body>
              <Card.Text>
                Im Sinne unserer Satzung soll die Vermittlung der
                geschichtlichen Inhalte zum Hohenfels und seiner Bewohner durch
                einen dualen Lehrpfad erfolgen, <br />
                welcher neben klassischen Hinweistafeln auch auf digital
                abrufbare Inhalte verweisen wird.
                <br />
              </Card.Text>
            </Card.Body>
          </Card>
        </Container>
      </div>
    );
  }
}

export default LectureTrail;
