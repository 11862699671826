import React, { Component } from "react";

import { Container, Card } from "react-bootstrap";
import { applyNavigationEffect } from "../../effects/NavigationEffect";
import CardWithImageComponent from "../../components/CardWithImageComponent.js";
//import CardWithVideoComponent from "../../components/CardWithVideoComponent.js";
import QuizComponent from "../../components/QuizComponent.js";

import AudioPlayer from "../../components/AudioPlayer.js";
import VideoPlayer from "../../components/VideoPlayer.js";

class EntranceBuchenau extends Component {
  componentDidMount() {
    applyNavigationEffect(); // Call the navigation effect
  }

  render() {
    return (
      <div id="lecture-trail-entrance-buchenau" className="webpage">
        <Container className="main-container">
          <Card>
            <Card.Header>Eingang Buchennau</Card.Header>
            <Card.Body>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              invidunt ut labore et dolore magna aliquyam erat, sed diam
              voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
              dolor sit amet.
            </Card.Body>
          </Card>

          <CardWithImageComponent
            id="current-picture"
            className="card-with-image"
            image="../../assets/images/general_images/ruine-einsturz.jpg"
            content={
              <div>
                Some text... <br />
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                sed diam voluptua. At vero eos et accusam et justo duo dolores
                et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                est Lorem ipsum dolor sit amet.
              </div>
            }
          />
          {/** 
        <CardWithVideoComponent
          id="video-card"
          className="card-with-video"
          videoUrl="https://www.youtube.com/embed/dQw4w9WgXcQ"
          header="Video Header"
          title="Video Title"
          text="Description of the video."
          content={<p>Some additional content</p>}
          footer="Video Footer"
        />
        */}

          <Card>
            <Card.Header> Audio </Card.Header>
            <Card.Body>
              <AudioPlayer
                audioSrc="/assets/audios/Mzg1ODMxNTIzMzg1ODM3_JzthsfvUY24.MP3"
                audioName="AudioGuide 001"
              />
            </Card.Body>
          </Card>
          <QuizComponent
            question="Was ist die Hauptstadt von Deutschland?"
            answers={["Berlin", "München", "Hamburg", "Köln", "Frankfurt"]}
            correctAnswer="Berlin"
          />
          <Card className="videoCard">
            <Card.Header> Some Video </Card.Header>
            <Card.Body>
              <VideoPlayer
                videoSrc="/assets/videos/12132744_1920_1080_24fps.mp4"
                videoName="Some awesome Video"
                videoType="video/mp4"
              />
            </Card.Body>
            <Card.Footer>
              <a href="/">Back to Start</a>
            </Card.Footer>
          </Card>
        </Container>
      </div>
    );
  }
}

export default EntranceBuchenau;
