import React, { Component } from "react";

import { Container, Button } from "react-bootstrap";
import { applyNavigationEffect } from "../../effects/NavigationEffect.js";
import CardWithImageComponent from "../../components/CardWithImageComponent";

import Satzung from "../../assets/files/BVH_Satzung.pdf";
class Statute extends Component {
  componentDidMount() {
    applyNavigationEffect(); // Call the navigation effect
  }
  render() {
    return (
      <div id="statute" className="webpage">
        <Container className="main-container">
          <CardWithImageComponent
            id="statute-site-card"
            className="card-with-image"
            header="Satzung des Vereins"
            image="../../assets/images/general_images/satzung.png"
            content={
              <a href={Satzung} download="BVH_Satzung" target="_blank">
                <Button className="btn btn-primary">Zur Satzung</Button>
              </a>
            }
          />
        </Container>
      </div>
    );
  }
}
export default Statute;
