import React from "react";
import { Card, Button } from "react-bootstrap";
import { BsPlayCircleFill } from "react-icons/bs";

/**
 * QuizComponent displays a quiz question with multiple-choice answers.
 *
 * @class QuizComponent
 * @extends React.Component
 *
 * @param {Object} props - The component props.
 * @param {string} props.question - The question to be asked in the quiz.
 * @param {Array<string>} props.answers - An array of possible answers.
 * @param {string} props.correctAnswer - The correct answer to the quiz question.
 *
 * @state {boolean} quizStarted - Indicates if the quiz has been started.
 * @state {string|null} selectedAnswer - The answer selected by the user.
 * @state {boolean} showResult - Indicates if the result of the answer should be shown.
 *
 * @example
 * <QuizComponent
 *   question="What is the capital of France?"
 *   answers={["Paris", "London", "Berlin", "Madrid"]}
 *   correctAnswer="Paris"
 * />
 */
class QuizComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quizStarted: false, // Controls whether the quiz has started
      selectedAnswer: null, // Stores the user's selected answer
      showResult: false, // Controls whether the answer has been checked
    };
    this.handleAnswerClick = this.handleAnswerClick.bind(this);
    this.startQuiz = this.startQuiz.bind(this);
  }

  /**
   * Starts the quiz by updating the state.
   */
  startQuiz() {
    this.setState({ quizStarted: true });
  }

  /**
   * Handles the answer click event.
   * @param {string} answer - The answer selected by the user.
   */
  handleAnswerClick(answer) {
    this.setState({
      selectedAnswer: answer,
      showResult: true,
    });
  }

  /**
   * Renders the QuizComponent.
   * @returns {JSX.Element} The rendered component.
   */
  render() {
    const { question, answers, correctAnswer } = this.props;
    const { quizStarted, selectedAnswer, showResult } = this.state;

    if (!quizStarted) {
      return (
        <div className="quiz-start">
          <Button
            variant="success"
            className="play-button"
            onClick={this.startQuiz}
            style={{ fontSize: "2rem", padding: "1rem 2rem" }}
          >
            <BsPlayCircleFill size={200} /> <br />
            Play Quiz
          </Button>
        </div>
      );
    }

    return (
      <div className="quiz-component">
        <Card className="quiz-card">
          <Card.Header>{question}</Card.Header>

          <Card.Body>
            {answers.map((answer, index) => (
              <Button
                key={index}
                className="quiz-answer"
                variant={
                  showResult
                    ? answer === correctAnswer
                      ? "success" // Highlight correct answer in green
                      : answer === selectedAnswer
                      ? "danger" // Highlight selected wrong answer in red
                      : "light" // Display other wrong answers in light gray
                    : "primary" // Default color for answers before selection
                }
                onClick={() => this.handleAnswerClick(answer)}
                disabled={showResult} // Disable buttons after an answer is selected
              >
                {answer}
              </Button>
            ))}
          </Card.Body>

          {showResult && (
            <Card.Footer>
              {selectedAnswer === correctAnswer ? (
                <div className="result correct">Richtig! Gut gemacht.</div>
              ) : (
                <div className="result incorrect">
                  Falsch! <br /> Die richtige Antwort ist: <br />
                  {correctAnswer}
                </div>
              )}
            </Card.Footer>
          )}
        </Card>
      </div>
    );
  }
}

export default QuizComponent;
