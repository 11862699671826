import React, { Component } from "react";
import { Container, Card } from "react-bootstrap";
import { applyNavigationEffect } from "../../effects/NavigationEffect";

class ModelsProject extends Component {
  componentDidMount() {
    applyNavigationEffect(); // Call the navigation effect
  }
  render() {
    return (
      <div id="project-modell" className="webpage">
        <Container className="main-container">
          <Card className="card-web">
            <Card.Header>Modellerstellung</Card.Header>
            {/** <Card.Img variant="center" src="../../images/general_images/modell.png" />*/}
            <Card.Body>
              <Card.Text>
                Zu beiden Anlagen (Ost und Westburg) soll ein Modell entstehen,{" "}
                <br />
                welches den Zeitgenössischen Zustand des Jahres 1250 abbilden
                soll. <br />
                Über einen öffentlicher Wettbewerb dafür wird derzeit noch
                diskutiert.
                <br />
              </Card.Text>
            </Card.Body>
          </Card>
        </Container>
      </div>
    );
  }
}

export default ModelsProject;
