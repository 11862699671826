import React, { Component } from "react";
import { Container, Card, Form, Button, Alert } from "react-bootstrap";
import axios from "axios";
import { applyNavigationEffect } from "../effects/NavigationEffect.js";

class GuestBook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entries: [],
      page: 0, // Tracks the current page of entries
      hasMore: true, // Tracks if there are more entries to show
      title: "",
      message: "",
      author: "",
      error: null,
      success: null,
    };
  }

  componentDidMount() {
    applyNavigationEffect(); // Call the navigation effect
    this.fetchEntries(); // Fetch initial entries
  }

  async fetchEntries() {
    const { page, entries } = this.state;

    try {
      const response = await axios.post(
        `https://burgverein-hohenfels.de/guestbook/getEntries?page=${page}`
      );
      const newEntries = response.data;

      this.setState({
        entries: [...entries, ...newEntries],
        hasMore: newEntries.length >= 5, // If less than 5 entries, no more pages
      });
    } catch (error) {
      console.error("Error fetching entries:", error);
    }
  }

  async handleSubmit(e) {
    e.preventDefault();
    const { title, message, author, entries } = this.state;

    if (!title || !message || !author) {
      this.setState({ error: "Alle Felder müssen ausgefüllt sein." });
      return;
    }

    try {
      const response = await axios.post("https://burgverein-hohenfels.de/guestbook/postEntry", {
        title,
        message,
        author,
      });

      if (response.status === 200) {
        this.setState({
          success: "Eintrag erfolgreich hinzugefügt!",
          entries: [
            { title, message, author, createdAt: new Date() },
            ...entries,
          ], // Add new entry locally
          title: "",
          message: "",
          author: "",
        });
      }
    } catch (error) {
      console.error("Error posting entry:", error);
      this.setState({ error: "Es gab einen Fehler beim Hinzufügen des Eintrags." });
    }
  }

  loadMore = () => {
    if (this.state.hasMore) {
      this.setState(
        (prevState) => ({ page: prevState.page + 1 }),
        () => this.fetchEntries()
      );
    }
  };

  render() {
    const { entries, hasMore, title, message, author, error, success } = this.state;

    return (
      <div id="guestbook" className="webpage">
        <Container className="main-container">
          <Card>
            <Card.Header>Gästebuch</Card.Header>
            <Card.Body>
              <Card.Text>
                Disclaimer: <br />
                Unser Gästebuch wird eigenhändig moderiert, um eine
                freundliche und respektvolle Atmosphäre zu gewährleisten. <br />
                Wir behalten uns das Recht vor, Einträge zu prüfen und
                gegebenenfalls ohne vorherige Ankündigung zu löschen, <br />
                insbesondere wenn sie beleidigend, unangemessen oder in anderer
                Weise gegen unsere Richtlinien verstoßen. <br />
                Vielen Dank für euer Verständnis und eure Beiträge!
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Header>Richtlinien</Card.Header>
            <Card.Body>
              <ol>
                <li>Respektvoller Umgang</li>
                <li>Keine Werbung</li>
                <li>Keine unangemessenen Inhalte</li>
                <li>Privatsphäre achten</li>
                <li>Kein Spam</li>
                <li>Konstruktive Kritik</li>
              </ol>
            </Card.Body>
          </Card>

          <div className="guestbook-form">
            <h1>Neuer Gästebuch Eintrag</h1>
            <Form onSubmit={(e) => this.handleSubmit(e)} className="card-form">
              {error && <Alert variant="danger">{error}</Alert>}
              {success && <Alert variant="success">{success}</Alert>}
              <Form.Group controlId="formTitle">
                <Form.Label>Titel</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Gib deinem Eintrag einen Titel"
                  value={title}
                  onChange={(e) => this.setState({ title: e.target.value })}
                />
              </Form.Group>

              <Form.Group controlId="formMessage" className="mt-3">
                <Form.Label>Nachricht</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Schreibe deine Nachricht"
                  value={message}
                  onChange={(e) => this.setState({ message: e.target.value })}
                />
              </Form.Group>

              <Form.Group controlId="formAuthor" className="mt-3">
                <Form.Label>Dein Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Dein Name"
                  value={author}
                  onChange={(e) => this.setState({ author: e.target.value })}
                />
              </Form.Group>
              <div className="btn-form-field">
                <Button className="mt-3" variant="primary" type="submit">
                  Eintrag hinzufügen
                </Button>
              </div>
            </Form>
          </div>

          {entries.map((entry, index) => (
            <Card key={index} className="mt-3">
              <Card.Header>{entry.title}</Card.Header>
              <Card.Body>
                <Card.Text>{entry.message}</Card.Text>
                <small>Autor: {entry.author}</small>
                <br />
                <small>
                  Datum: {new Date(entry.createdAt).toLocaleString()}
                </small>
              </Card.Body>
            </Card>
          ))}

          {hasMore && entries.length > 0 && (
            <Button onClick={this.loadMore}>Mehr anzeigen</Button>
          )}
        </Container>
      </div>
    );
  }
}

export default GuestBook;
